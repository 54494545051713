import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import wheel from "../static/wheel.jpg";
import icon from "../static/icon.svg";

export default function HowToPlay() {
    return (
        <Row className="outer">
            <Col xs={12} lg={5} className="how-to-image-col">
                <Image src={wheel} roundedCircle alt="" />
            </Col>
            <Col xs={12} lg={5}>
                <h4>How to play?</h4>
                <div className="list-text">
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">The goal is to achieve deep decarbonisation by 2050</Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">Teams representing countries compete with each other</Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">
                            Five indicators to measure success: greenhouse gas emission, energy poverty level, household
                            savings, unemployment rate and land use pressure
                        </Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">
                            Three policy scenarios to choose from: 1. Energy Budget Scheme, 2. Carbon tax, 3. Green
                            economy toolbox
                        </Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">Decisions are made for one decade about several parameters under each policy scenario</Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">
                            Unexpected events might happen, like economic recession, extreme weather events and arrival
                            of climate refugees
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
}
