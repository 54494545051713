import * as React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function PlaySummary() {
    return (
        <Jumbotron className="play-jumbotron">
            <p className="game-summary">
                <span> Decarbonise!</span> is a decision-making simulation game, which is
                played in teams. The goal is achieving carbon neutrality by 2050, while also considering social,
                economic and other environmental factors. The teams can choose from three policy scenarios and make
                decisions on various aspects for every decade between 2020 and 2050. The team with the best results for
                all five success indicators wins the game, but the true aim is to spark debate, promote holistic
                thinking and raise awareness on different policy approaches to tackle the climate crisis.
            </p>
        </Jumbotron>
    );
}
