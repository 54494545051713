import * as React from "react";
import { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";

export default function ContactForm() {
    const [name, setName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [message, setMessage] = useState<string>();

    const handleNameInputChange = (e: any) => {
        setName(e.target.value);
    };

    const handleEmailInputChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handleMessageInputChange = (e: any) => {
        setMessage(e.target.value);
    };

    const sendMessage = () => {
        let templateParams = {
            name: name,
            email: email,
            message: message,
        };
        let templateID = "contact_form";

        let serviceID = "decarbonise";
        console.log(email);
        console.log(message);
        init("user_NlgaXv5BDOku3Q5GF3cvp");
        emailjs.send(serviceID, templateID, templateParams);
        alert("Message sent!");
    };

    return (
        <div>
            <Form onSubmit={sendMessage}>
                <Form.Group>
                    <Form.Label>Your name</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={handleNameInputChange}
                        required
                        value={name}
                        id="name"
                        name="name"
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Your email</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={handleEmailInputChange}
                        required
                        value={email}
                        id="email"
                        name="email"
                    />
                    <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                </Form.Group>
                <br />
                <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={handleMessageInputChange}
                        required
                        value={message}
                        id="message"
                        name="message"
                    />
                </Form.Group>

                <Button variant="warning" type="submit">
                    Send
                </Button>
            </Form>
        </div>
    );
}
