import * as React from "react";

export default function PlaySummary() {
    return (
        <div>
            <div className="home-jumbotron">
                <div className="home-jumbotron-summary">
                    <p>
                        <span>Decarbonise!</span> is a decision-making simulation game on how to tackle the climate
                        crisis. Teams compete to achieve carbon neutrality by choosing the right policy choices and
                        inputs, while considering social, economic and land use consequences as well.
                    </p>
                </div>
            </div>
        </div>
    );
}
