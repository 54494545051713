import * as React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import document from "../static/documentlight.svg";
import game2 from "../static/game2.jpg";
import gameInstructions from "../static/Decarbonise_Instructions_for_players.pdf";
import facilitatorDoc from "../static/Decarbonise_Instructions_Background_facilitators.pdf";
import software from "../static/Decarbonise_2021March.zip";

export default function Downoads() {
    return (
        <div>
            <Row>
                <Col>
                    <p className="downloads-text-top">
                        Here you find the simulation software and the instructions and background document that you need
                        for playing the game.
                    </p>
                </Col>
            </Row>
            <Row className="download-card-row">
                <Col className="download-card-col" lg={3} sm={12} md={6}>
                    <div className="download-card-top-faci">
                        <Image className="download-card-software-img" src={game2} />
                    </div>
                    <div className="download-card-text-box">
                        <div className="download-card-title-box">
                            <h5 className="download-card-title">Decarbonise! software for facilitators</h5>
                        </div>
                        <p className="download-card-text">
                            Desktop application running on Windows (Windows 7 and above). It simulates the decisions of
                            the teams, shows the success indicators in charts, generates events. Only the game
                            facilitator needs to run the software.
                        </p>
                        <div className="download-card-button">
                            <Button variant="warning">
                                <a href={software} download="Decarbonise_software.zip">
                                    Download
                                </a>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={3} sm={12} md={6} className="download-card-col">
                    <div className="download-card-top-icon-faci">
                        <Image src={document} className="download-icon-top" />
                    </div>
                    <div className="download-card-text-box">
                        <div className="download-card-title-box">
                            <h5 className="download-card-title">
                                Game instructions and background document for facilitators
                            </h5>
                        </div>
                        <p className="download-card-text">
                            Game instructions and background document about the policy scenarios for facilitators.
                        </p>
                        <div className="download-card-button">
                            <Button variant="warning">
                                <a href={facilitatorDoc} download="Game_instructions_backg_doc_faci.pdf">
                                    Download
                                </a>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={3} sm={12} md={6} className="download-card-col">
                    <div className="download-card-top-icon-player">
                        <Image src={document} className="download-icon-top" />
                    </div>
                    <div className="download-card-text-box">
                        <div className="download-card-title-box">
                            <h5 className="download-card-title">Game instructions for players</h5>
                        </div>
                        <p className="download-card-text">
                            Game instructions about the rules of the game, the policy scenarios and decision parameters
                            for players.
                        </p>

                        <div className="download-card-button">
                            <Button variant="warning">
                                <a href={gameInstructions} download="Game_instructions_players.pdf">
                                    Download
                                </a>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
