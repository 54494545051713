import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import advocate from "../static/advocate.jpg";
import politician from "../static/politician.jpg";
import professor from "../static/professor.jpg";
import business from "../static/business.jpg";
import activist from "../static/activist.jpg";

export default function TargetGroup() {
    return (
        <div>
            <h3>We developed this game for...</h3>
            <Row className="outer">
                <Col lg={4}>
                    <Row className="target-image-row">
                        <Image src={professor} roundedCircle alt="professor" />
                    </Row>
                    <Row className="target-group">
                        <div className="row-text">
                            <h6>...educators</h6>
                            <p className="target-group-description">
                                Experience based education is a most efficient way to deepen knowledge. Play this game
                                with your students and engage them in a critical debate about climate policies, the
                                interconnectedness of different sectors and related decision-making.
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="target-image-row">
                        <Image src={activist} roundedCircle alt="activist" />
                    </Row>
                    <Row className="target-group">
                        <div className="row-text">
                            <h6>...campaigners</h6>
                            <p className="target-group-description">
                                Make your point with hands-on experiences! Play this decision-making simulation game
                                with other activists and engage them in calling for systemic change to effectively
                                tackle the climate crisis!
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="target-image-row">
                        <Image src={politician} roundedCircle alt="politician" />
                    </Row>
                    <Row className="target-group">
                        <div className="row-text">
                            <h6>...policy makers</h6>
                            <p className="target-group-description">
                                Want to see at once how your decisions impact the environment and the people? Convince
                                your team about your preferred climate policy approach and debate the results!
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="target-image-row">
                        <Image src={advocate} roundedCircle alt="advocate" />
                    </Row>
                    <Row className="target-group">
                        <div className="row-text">
                            <h6>...policy advocates</h6>
                            <p className="target-group-description">
                                Do you believe that sustainability should be achieved with holistic policies that
                                consider environmental, social and economic impacts at the same time? Get to know
                                different climate policy choices and engage in a debate about delivering system change!
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="target-image-row">
                        <Image src={business} roundedCircle alt="business" />
                    </Row>
                    <Row className="target-group">
                        <div className="row-text">
                            <h6>...company employees</h6>
                            <p className="target-group-description">
                                Are you looking for a team building exercise? Is your company committed to
                                sustainability? Then this game might be the right choice for a few hours of fun with
                                your colleagues.
                            </p>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
