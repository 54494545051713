import * as React from "react";
import HowToPlay from "./HowToPlay";
import PlaySummary from "./PlaySummary";
import WhatYouNeed from "./WhatYouNeed";

export default function Play() {
    return (
        <div>
            <PlaySummary />
            <HowToPlay />
            <WhatYouNeed />
        </div>
    );
}
