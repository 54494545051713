import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./components/Header";
import Home from "./components/Home";
import Play from "./components/Play";
import About from "./components/About";
import Footer from "./components/Footer";
import Downloads from "./components/Downloads";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/play" component={Play} />
                    <Route path="/about" component={About} />
                    <Route path="/downloads" component={Downloads} />
                </Switch>
            </div>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
