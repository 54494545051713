import * as React from "react";
import OpinionCarousel from "./OpinionCarousel";
import TargetGroup from "./TargetGroup";
import HomeJumbotron from "./HomeJumbotron";
import WhyList from "./WhyList";

export interface IHomeProps {}

export default function Home(props: IHomeProps) {
    return (
        <div>
            <HomeJumbotron />
            <WhyList />
            <TargetGroup/>
            
        </div>
    );
}
