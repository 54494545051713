import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import sun2 from "../static/sun2.jpg";
import icon from "../static/icon.svg";

export default function WhyList() {
    return (
        <Row className="outer">
            <Col xs={12} lg={5}>
                <h4>Why such a game?</h4>
                <div className="list-text">
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col"> Enhancing awareness on the climate crisis and related social and economic challenges</Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">
                            Promoting policy tools targeting carbon neutrality and showing their impacts on the society and
                            economy
                        </Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">Encouraging multistakeholder cooperation integrating different views and interests </Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">Catalyzing joint thinking on how to tackle the climate crisis</Col>
                    </Row>
                    <Row>
                        <Col className="check-icon">
                            <img src={icon} alt="" />
                        </Col>
                        <Col className="list-item-col">Playing is fun!</Col>
                    </Row>
                </div>
            </Col>
            <Col xs={12} lg={5} className="why-image-col">
                <Image src={sun2} roundedCircle alt="Green bulb" />
            </Col>
        </Row>
    );
}
