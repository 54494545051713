import * as React from "react";
import { useLocation } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../static/icon-text.png";

export interface IHeaderProps {}

export default function Header(props: IHeaderProps) {
    const location = useLocation();

    return (
        <Container>
            <Navbar expand="lg" className="justify-content-between">
                <Nav>
                    <Navbar.Brand>
                        <img src={logo} className="d-inline-block align-top" height="40px" alt="Decarbonise! logo" />
                    </Navbar.Brand>
                </Nav>
                <Nav>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav.Link id="nav-link-home " className={location.pathname === "/" ? "nav-link-active" : "nav-link"} href="/">
                            Home
                        </Nav.Link>
                        <Nav.Link
                            className={location.pathname === "/play" ? "nav-link-active" : "nav-link"}
                            href="/play"
                        >
                            Play the game
                        </Nav.Link>
                        <Nav.Link
                            className={location.pathname === "/downloads" ? "nav-link-active" : "nav-link"}
                            href="/downloads"
                        >
                            Downloads
                        </Nav.Link>
                        <Nav.Link
                            className={location.pathname === "/about" ? "nav-link-active" : "nav-link"}
                            href="/about"
                        >
                            About
                        </Nav.Link>
                    </Navbar.Collapse>
                </Nav>
            </Navbar>
        </Container>
    );
}
