import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import code from "../static/code.svg";
import house from "../static/house.svg";
import document from "../static/document.svg";
import laptop from "../static/laptop.svg";
import person from "../static/person.svg";
import people from "../static/people.svg";

export default function WhatYouNeed() {
    return (
        <div>
            <h3>What will you need?</h3>
            <Row className="outer">
                <Col lg={4}>
                    <Row className="need-icon-row">
                        <img src={people} alt="" />
                    </Row>
                    <Row>
                        <div className="row-text">
                            <p>
                                Ideally 10-30 people (16+ year-olds) coming together in person or virtually for 2-3
                                hours of fun and debate{" "}
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="need-icon-row">
                        <img src={code} alt="" />
                    </Row>
                    <Row>
                        <div className="row-text">
                            <p> Our Decarbonise! simulation software, which will run on the facilitator's computer </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="need-icon-row">
                        <img src={document} alt="" />
                    </Row>
                    <Row>
                        <div className="row-text">
                            <p>
                                Game instructions for the game facilitator and for the players, which they should read
                                beforehand. In addition to the rules of the game these instructions include information
                                about the policy choices and the input options.
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="need-icon-row">
                        <img src={person} alt="" />
                    </Row>
                    <Row>
                        <div className="row-text">
                            <p>
                                A facilitator with more background knowledge makes the play run smoothly and helps teams
                                to overcome obstacles. The facilitator also runs the software and registers the
                                decisions. You can choose the facilitator from among the players, but you can also
                                contact us, if you would like to involve an external one.
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="need-icon-row">
                        <img src={laptop} alt="" />
                    </Row>
                    <Row>
                        <div className="row-text">
                            <p>
                                The facilitator needs a computer, but if you play in person a projector makes easier for
                                the players to follow the decisions, events and success indicators.
                            </p>
                        </div>
                    </Row>
                </Col>
                <Col lg={4}>
                    <Row className="need-icon-row">
                        <img src={house} alt="" />
                    </Row>
                    <Row>
                        <div className="row-text">
                            <p>
                                Ideally the players can gather at a venue, engage in personal debate and follow the game
                                on a projected screen. However, it is also possible to organise an online event with the
                                help of a meeting app. In this case the facilitator can share the screen.
                            </p>
                        </div>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
