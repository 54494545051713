import * as React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";
import Image from "react-bootstrap/Image";
import vera from "../static/vera.jpg";
import klara from "../static/klara.jpg";
import ContactForm from "./ContactForm";

export default function About() {
    return (
        <div>
            <Row className="outer">
                <Col lg={6}>
                    <Row>
                        <h3>About us</h3>
                        <div className="about">
                            <span>Veronika Kiss</span> and <span>Klára Hajdu</span> were engaged in EU policy advocacy
                            and campaigning for more than 10 years. Promoting the absolute reduction of resource use
                            with the help of the European Resource Cap Coalition, they were calling for policy
                            approaches that are able to lead to systemic change. However, they experienced that policy
                            makers and other stakeholders far too often ignore other sectoral impacts and are rarely
                            open for innovative policy choices. With this simulation game their ultimate goal is to open
                            up debate about lesser known policy proposals.
                        </div>
                    </Row>
                    <Row>
                        <CardGroup className="about-card-group">
                            <Card>
                                <div className="about-card-image-div">
                                    <Image roundedCircle src={vera} />
                                </div>
                                <Card.Body>
                                    <Card.Title>Veronika Kiss</Card.Title>
                                    <Card.Text className="about-text">
                                        Veronika is to receive her PhD on ecological economics after 10 years of
                                        research and policy experience in the international environmental arena working
                                        with the UN, the European Union and national governments. She was one initiator
                                        of the European Resource Cap Coalition in 2010. Her key expertise lies in
                                        sustainability, especially household energy consumption. In case you need
                                        Veronika’s facilitation to play the game, please contact us.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <Card>
                                <div className="about-card-image-div">
                                    <Image roundedCircle src={klara} />
                                </div>
                                <Card.Body>
                                    <Card.Title>Klára Hajdu</Card.Title>
                                    <Card.Text className="about-text">
                                        Klára spent more than 15 years in the NGO sector dealing with environmental and
                                        sustainability policies, including the promotion of the Energy Budget Scheme and
                                        co-leading a European campaign for the sustainability reform of the EU budget
                                        with SDG Watch Europe. 
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </Row>
                </Col>
                <Col lg={6}>
                    <div className="about-contact-div">
                        <div className="about-contact-inner-div">
                            <h3>Contact us</h3>
                            <div className="about">
                                If you have any questions or suggestions about the game, or you would like to involve
                                external facilitators, please contact us in the form below.
                            </div>
                            <ContactForm />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
