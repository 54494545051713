import * as React from "react";
import logo from "../static/BCE-logo.png";

export default function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="logo-div">
          <img height="55px" src={logo} />
        </div>
      </div>
        <div className="support-div">
          This website is developed with the financial support of the Hungarian
          National Bank.
        </div>
      <footer>
        <div>
          <p> Kiss - Hajdu - 2021</p>
        </div>
      </footer>
    </>
  );
}
